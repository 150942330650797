import {forwardRef, useState, useEffect} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Divider, Image, Select, Tabs, Typography} from 'antd';
import localization from 'localization';
import ProTable from '@ant-design/pro-table';

// Global utils
import {getPlatformImage, handleErrorFetch, moneyFormat, merchantOrderStatusHandler, pairingMethodMapper} from 'utils/utils';
import {getOrderHistory} from 'utils/request/watchtower';
import {ORDER_MERCHANT_STATUS_CONST, POS_ORDER_STATUS, POS_PAIR_STATUS} from 'utils/constants';

// Local components
import OrderStatus from '../OrderStatus';
import {capitalize, isEmpty} from 'lodash';

const MANUAL_FLAGS = [{label: 'POS', value: 'POS'}, {label: 'Manual', value: 'manual'}, {label: 'Robot', value: 'robot'}];

const TableOrderList = forwardRef(({
	metricFormRef, onClickDetail, onClickVoid, onClickEditPair, platformList, dataCount, isPos,
}, ref) => {
	const locale = localization.WatchTower.WatchTowerOrder.orderList;

	const [activeTab, setActiveTab] = useState('uninjected');

	const columns = [
		{
			key: 'orderTime',
			dataIndex: 'createdAt',
			title: locale.orderTime,
			render: (_, {createdAt}) => moment(createdAt).format('DD MMM YYYY - HH:mm'),
			defaultSortOrder: 'descend',
			search: false,
			sorter: true,
		},
		{
			key: 'location',
			title: locale.location,
			render: ({Merchant}) => Merchant.Location.label,
			search: false,
		},
		{
			key: 'externalAltId',
			title: locale.orderId,
			render: ({externalAltId}) => externalAltId,
		},
		{
			key: 'externalId',
			title: locale.externalId,
			render: ({externalId}) => externalId?.slice?.(-5),
			formItemProps: {
				rules: [
					{
						min: 5,
						message: locale.externalIdValidation,
					},
				],
			},
		},
		{
			key: 'platformId',
			title: locale.platform,
			filters: platformList.map(platform => ({
				text: platform.label,
				value: platform.id,
			})),
			render: (_, {Merchant}) => {
				const platformImage = getPlatformImage(Merchant.Platform.label);
				return (
					<div className="flex gap-2">
						<Image
							width={22}
							height={22}
							src={platformImage?.default}
							preview={false}
						/>
						{Merchant.Platform.label}
					</div>
				);
			},
			search: false,
		},
		{
			key: 'posStatus',
			title: locale.orderStatus,
			render: (_, {posStatus}) => <OrderStatus status={posStatus} />,
			search: false,
			filters: [
				{
					text: 'Pending',
					value: 'pending',
				},
				{
					text: 'On Proccess',
					value: 'onProccess',
				},
				{
					text: 'Done',
					value: 'done',
				},
			],
		},
		{
			key: 'type',
			title: locale.orderType,
			render: (_, {type}) => <span className='capitalize'>{type}</span>,
			search: false,
			filters: [
				{
					text: 'Delivery',
					value: 'delivery',
				},
				{
					text: 'Takeaway',
					value: 'takeaway',
				},
			],
		},
		{
			key: 'status',
			title: locale.merchantStatus,
			render: (_, {status, type, pickUpReadyAt}) => (
				<span>
					{merchantOrderStatusHandler({status, type, pickUpReadyAt})}
				</span>
			),
			search: false,
			filters: Object.values(ORDER_MERCHANT_STATUS_CONST).map(merchantStatus => {
				return {
					text: merchantStatus.label,
					value: merchantStatus.value,
				};
			}),
		},
		{
			key: 'processedBy',
			title: locale.manualFlag,
			// eslint-disable-next-line react/prop-types
			render: ({processedBy}) => processedBy ? <span className='capitalize'>{processedBy}</span> : '-',
			renderFormItem: () => (
				<Select
					mode="multiple"
					allowClear
					placeholder="Please select"
				>
					{MANUAL_FLAGS.map(({label, value}) => (
						<Select.Option
							key={value}
							value={value}>{label}</Select.Option>
					))}
				</Select>
			),
		},
		{
			key: 'brand',
			title: locale.brand,
			render: ({Merchant}) => Merchant.Brand.label,
			search: false,
		},
		{
			key: 'totalAmount',
			title: locale.totalAmount,
			render: (_, record) => moneyFormat({value: record?.grandTotal}),
			search: false,
		},
		{
			key: 'action',
			title: locale.action,
			align: 'center',
			fixed: 'right',
			search: false,
			width: 115,
			render: (_, record) => (
				<a
					className='text-antd-blue-6'
					onClick={() => onClickDetail(record)}>
					{locale.actionDetail}
				</a>
			),
		},
	];

	const posColumns = [
		{
			key: 'orderTime',
			dataIndex: 'acceptedAt',
			title: locale.orderTime,
			render: (_, {acceptedAt}) => moment(acceptedAt).format('DD MMM YYYY - HH:mm'),
			defaultSortOrder: 'descend',
			search: false,
			sorter: true,
			width: 195,
		},
		{
			key: 'billNumber',
			title: locale.billNumber,
			render: ({billNumber}) => billNumber,
			width: 139,
		},
		{
			key: 'merchantOrderId',
			title: locale.orderId,
			render: ({merchantOrderId}) => merchantOrderId || '-',
			width: 155,
		},
		{
			key: 'externalId',
			title: locale.externalId,
			render: ({orderDetails}) => orderDetails?.externalId?.slice?.(-5) || '-',
			formItemProps: {
				rules: [
					{
						min: 5,
						message: locale.externalIdValidation,
					},
				],
			},
		},
		{
			key: 'location',
			title: locale.location,
			render: ({Merchant}) => Merchant.Location.label,
			search: false,
		},
		{
			key: 'platformId',
			title: locale.platform,
			filters: platformList.map(platform => ({
				text: platform.label,
				value: platform.id,
			})),
			render: (_, {Merchant}) => {
				const platformImage = getPlatformImage(Merchant.Platform.label);
				return (
					<div className="flex gap-2">
						<Image
							width={22}
							height={22}
							src={platformImage?.default}
							preview={false}
						/>
						{Merchant.Platform.label}
					</div>
				);
			},
			search: false,
		},
		{
			key: 'brand',
			title: locale.brand,
			render: ({Merchant}) => Merchant.Brand.label,
			search: false,
		},
		{
			key: 'status',
			title: locale.orderStatus,
			render: (_, {status}) => <OrderStatus status={status} />,
			search: false,
			width: 169,
			filters: Object.entries(POS_ORDER_STATUS).map(orderStatus => {
				return {
					text: capitalize(orderStatus[0]),
					value: orderStatus[1],
				};
			}).sort((a, b) => a.text.localeCompare(b.text)),
		},
		{
			key: 'isDriverCancel',
			title: locale.driverCancel,
			render: (_, {reimbursement}) =>{
				return isEmpty(reimbursement)
					? '-'
					: (
						<div className={reimbursement?.completedItems?.length ? 'text-antd-green-6' : 'text-antd-red-6'}>
							{reimbursement?.completedItems?.length ? locale.yes : locale.no}
						</div>
					);
			},
			search: false,
			width: 169,
			filterMultiple: false,
			filters: [
				{text: 'Yes', value: true},
				{text: 'No', value: false},
			],
		},
		{
			key: 'type',
			title: locale.orderType,
			render: (_, {type}) => <span className='capitalize'>{type}</span>,
			search: false,
			width: 158,
			filters: [
				{
					text: 'Delivery',
					value: 'delivery',
				},
				{
					text: 'Takeaway',
					value: 'takeaway',
				},
			],
		},
		{
			key: 'subtotal',
			title: locale.totalAmount,
			render: ({grandTotal}) => moneyFormat({value: grandTotal}),
			search: false,
			width: 161,
		},
		{
			key: 'paymentMethod',
			title: locale.paymentMethod,
			dataIndex: ['PosPaymentMethod', 'name'],
			// render: paymentMethodId => paymentMethodId,
			search: false,
			width: 186,
		},
		{
			key: 'merchantStatus',
			title: locale.merchantStatus,
			render: (_, {orderDetails, type}) => (
				<span>
					{merchantOrderStatusHandler({status: orderDetails?.status, type, pickUpReadyAt: orderDetails?.pickUpReadyAt})}
				</span>
			),
			search: false,
			filters: Object.values(ORDER_MERCHANT_STATUS_CONST).map(merchantStatus => {
				return {
					text: merchantStatus.label,
					value: merchantStatus.value,
				};
			}),
		},
		{
			key: 'processBy',
			title: locale.processBy,
			render: ({isManualInput}) => isManualInput ? 'POS' : 'Robot',
			search: false,
			filters: [
				{
					label: 'Robot',
					value: 'Robot',
				},
				{
					label: 'POS',
					value: 'POS',
				},
			].map(merchantStatus => {
				return {
					text: merchantStatus.label,
					value: merchantStatus.value,
				};
			}),
			width: 145,
		},
		{
			key: 'orderFlag',
			title: locale.orderFlag,
			render: ({isRatingBoost}) => isRatingBoost ? 'Rating Boost' : '-',
			search: false,
			filters: [
				{
					text: 'Rating Boost',
					value: 'isRatingBoost',
				},
			],
			width: 145,
		},
		{
			key: 'pairStatus',
			title: locale.pairStatus,
			dataIndex: 'pairStatus',
			render: pairStatus => <span className='capitalize'>{pairStatus}</span>,
			search: false,
			width: 160,
		},
		{
			key: 'pairMethod',
			title: locale.pairMethod,
			dataIndex: 'pairMethod',
			render: pairMethod => <span className='capitalize'>{pairingMethodMapper(pairMethod)}</span>,
			search: false,
			width: 160,
		},
		{
			key: 'cashier',
			title: locale.cashier,
			dataIndex: ['creator', 'username'],
			search: false,
			width: 174,
		},
		{
			key: 'editedBy',
			title: locale.editedBy,
			dataIndex: ['updater', 'username'],
			search: false,
			width: 174,
		},
		{
			key: 'updatedAt',
			title: locale.updatedAt,
			dataIndex: 'updatedAt',
			search: false,
			width: 180,
			render: (_, {updatedAt}) => moment(updatedAt).format('DD MMM YYYY - HH:mm'),
		},
		{
			key: 'action',
			title: locale.action,
			align: 'center',
			fixed: 'right',
			search: false,
			width: isPos ? 213 : 203,
			render: (_, record) => {
				const isInternalPlatform = record?.Merchant?.Platform?.type === 'internal';
				return (
					<div className='flex items-center justify-center'>
						<a
							className='text-antd-blue-6'
							onClick={() => onClickDetail(record)}>
							{locale.actionDetail}
						</a>
						{!isInternalPlatform && record.isManualInput && !record.voidedAt && (
							<>
								<Divider type='vertical' />
								<a
									className='text-antd-blue-6'
									onClick={() => onClickVoid(record)}
								>
									{locale.void}
								</a>
							</>
						)}
						{!isInternalPlatform && record.isManualInput && !record.voidedAt && !isEmpty(record.merchantOrder) && (
							<>
								<Divider type='vertical' />
								<a
									className='text-antd-blue-6'
									onClick={() => onClickEditPair(record)}
								>
									{locale.editPairing}
								</a>
							</>
						)}
					</div>
				);
			},
		},
	];

	// Methods
	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const fetchTableData = async (
		params, sorter, filters,
	) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			const metricPayload = metricFormRef.current?.getMetricFilter?.();

			if (!params?.processedBy?.length) delete params?.processedBy;
			const queryParams = {
				isPos,
				sortBy: 'createdAt',
				sortOrder: sortDirectionConverter(sorter.createdAt),
				limit: params.pageSize,
				page: params.current - 1,
				processedBy: params?.processedBy || null,
				externalAltId: params?.externalAltId || null,
				externalId: params?.externalId || null,
				billNumber: params?.billNumber || null,
				merchantOrderId: params?.merchantOrderId || null,
				...metricPayload,
				...filters,
			};
			if (isPos) {

				if (queryParams?.orderFlag?.length) {
					queryParams?.orderFlag.forEach(flag => {
						queryParams[flag] = true;
					});
					delete queryParams?.orderFlag;
				}

				if (queryParams?.processBy?.length) {

					if (queryParams?.processBy.every(item => item === 'POS')) queryParams.isManualInput = true;
					if (queryParams?.processBy.every(item => item === 'Robot')) queryParams.isManualInput = false;

					delete queryParams?.processBy;
				}

				queryParams.pairStatus = activeTab === 'injected'
					? [POS_PAIR_STATUS.DONE, POS_PAIR_STATUS.VOID]
					: [POS_PAIR_STATUS.PENDING, POS_PAIR_STATUS.PENDING_LATER];


				if (queryParams?.isDriverCancel?.length) {
					queryParams.isDriverCancel = queryParams?.isDriverCancel[0];
				}

			} else {
				queryParams.isRecon = activeTab === 'unrecorded';
				queryParams.isInjected = activeTab === 'unrecorded' ? null : activeTab === 'injected';
			}
			const response = await getOrderHistory(queryParams);
			metricFormRef.current?.fetchMetricData?.();
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};
	// Lifecycle
	useEffect(() => {
		ref.current?.submit?.();
	}, [activeTab, isPos]);
	// Render
	return (
		<div className="tableCard my-4 mx-6 pt-4 border border-antd-netural-4">
			<div className='px-4 flex flex-col gap-2'>
				<Typography.Text className="text-base font-medium">{locale.title}</Typography.Text>
				<Tabs
					onTabClick={key => setActiveTab(key)}
					activeKey={activeTab}
				>
					<Tabs.TabPane
						tab={(
							<div>
								{isPos ? locale.problematic : locale.uninjected}
								<span className='ml-1 px-2 bg-antd-blue-1 text-antd-blue-6 rounded-lg'>{
									(isPos
										? dataCount?.problematicCount
										: dataCount?.notInjectedCount) || 0
								}</span>
							</div>
						)}
						key="uninjected" />
					<Tabs.TabPane
						tab={(
							<div>
								{isPos ? locale.notProbelmatic : locale.injected}
								<span className='ml-1 px-2 bg-antd-blue-1 text-antd-blue-6 rounded-lg'>{
									(isPos
										? dataCount?.notProblematicCount
										: dataCount?.injectedCount) || 0
								}</span>
							</div>
						)}
						key="injected" />
					{
						!isPos && (
							<Tabs.TabPane
								tab={locale.unrecorded}
								key="unrecorded" />
						)
					}
				</Tabs>
			</div>
			<ProTable
				formRef={ref}
				className="mt-2"
				rowKey='id'
				columns={isPos ? posColumns : columns}
				search={{layout: 'vertical'}}
				form={{
					ignoreRules: false,
				}}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				layout="vertical"
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
});

TableOrderList.defaultProps = {
	metricFormRef: {},
	onClickDetail: () => {},
	onClickVoid: () => {},
	onClickEditPair: () => {},
	platformList: [],
	dataCount: {},
	isPos: false,
};

TableOrderList.propTypes = {
	metricFormRef: PropTypes.object,
	onClickDetail: PropTypes.func,
	onClickVoid: PropTypes.func,
	onClickEditPair: PropTypes.func,
	platformList: PropTypes.array,
	dataCount: PropTypes.object,
	isPos: PropTypes.bool,
};

export default TableOrderList;
