import React, {forwardRef, useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Context from '../../Context';

import {ArrowRightOutlined, DeleteOutlined, EditOutlined, ExclamationCircleFilled, EyeInvisibleOutlined, EyeOutlined, MenuOutlined, ShopOutlined, ShoppingCartOutlined, TagOutlined} from '@ant-design/icons';
import {Dropdown, Image, Menu, Modal, notification, Tag, Typography} from 'antd';
const {Text} = Typography;

import {AVAILABLE_POS, STORAGE_KEY} from 'utils/constants';
import {getStationLabel, moneyFormat} from 'utils/utils';

import localization from 'localization';
import {get} from 'lodash';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory;

const MenuItem = forwardRef((
	{menu, dragHandleProps, openMenuDrawer, removeMenu, promoView, viewOnly, unmapMenuPosIds, duplicateChildMenuExist,
		locationFilterSetting, openMenuTag, toggleMenuHidden, openOrderMenuDrawer, ...restProps}, ref) => {
	const {previewAs, menuTagList} = useContext(Context);
	const OpenDrawerIcon = viewOnly ? EyeOutlined : EditOutlined;

	const isUnmap = unmapMenuPosIds.length;

	const menuIsHidden = get(menu, 'hideInPlatform', false);

	const goToMapMenu = posId => {
		sessionStorage.setItem(STORAGE_KEY.MASTERLIST_ITEM_DETAIL, JSON.stringify({menuDetails: menu, mappingDetails: {}}));
		window.open(`/masterlist/menu-structure/${posId}/new`, '_blank');
	};

	const getPosLabel = posLabel => {
		switch (posLabel) {
		case AVAILABLE_POS.ESB.label:
			return 'ERP';
		case AVAILABLE_POS.ODOO.label:
			return 'KDS';
		default:
			return posLabel;
		}
	};

	const beforeRemoveMenu = () => {
		Modal.confirm({
			title: locale.RemoveMenuModal.title,
			content: locale.RemoveMenuModal.content,
			centered: true,
			maskClosable: true,
			onOk: () => {
				removeMenu();
				notification.open({
					message: locale.RemoveMenuNotification.message,
					description: locale.RemoveMenuNotification.description,
					type: 'success',
				});
			},
			okButtonProps: {danger: true},
			autoFocusButton: null,
			okText: locale.RemoveMenuModal.okText,
			cancelText: locale.RemoveMenuModal.cancelText,
		});
	};

	return (
		<div
			ref={ref}
			{...restProps}
			className='flex items-center border border-antd-netural-4 bg-white'>
			{
				!viewOnly && (
					<div
						{...dragHandleProps}
						className='self-stretch flex items-center p-4'>
						<MenuOutlined />
					</div>
				)
			}
			<div className={clsx(
				'flex flex-1 items-center py-12',
				viewOnly
					? 'pl-8'
					: 'pl-4',
			)}>
				{
					menu.imageUrl
						? (
							<Image
								className={clsx('rounded-md', menuIsHidden && ('filter grayscale'))}
								src={menu.imageUrl}
								height={104}
								width={104} />
						)
						: (
							<div
								className='w-24 rounded-md h-24 bg-antd-netural-4 flex flex-col gap-2 items-center justify-center text-gray-500 select-none'>
								{'No Image'}
							</div>
						)
				}
				<div className='flex flex-col pl-8 flex-1'>
					{/* Tag Section */}
					<div className='flex gap-2'>
						{
							getStationLabel(menu?.menuStation)
								? (
									<Tag className='self-start flex items-center m-0 gap-1'>
										<ArrowRightOutlined />
										{getStationLabel(menu?.menuStation)}
									</Tag>
								)
								: null
						}
						{
							(locationFilterSetting.type === 'default' || !locationFilterSetting.type) ? null : (
								<Tag className='self-start flex items-center m-0'>
									<ShopOutlined />
									{locale?.[locationFilterSetting.type]?.replace?.('{{qty}}', locationFilterSetting.locationIds.length)}
								</Tag>
							)
						}
						{
							menuIsHidden && (
								<Tag className='self-start flex gap-2 items-center m-0'>
									<EyeInvisibleOutlined />
									{locale.menuIsHidden}
								</Tag>
							)
						}
					</div>
					<Text className='font-medium pt-2'>{menu.menuLabel}</Text>
					<div className='flex'>
						<Text
							className={clsx(
								promoView && 'text-white-overlay-20',
							)}
							delete={promoView}>{moneyFormat({value: menu.menuPrice})}</Text>
						{
							menu.kdsName && (
								<Text
									type='secondary'
									className='ml-1'>{` · ${menu.kdsName}`}
								</Text>
							)
						}
					</div>
					{
						promoView ? (
							<>
								<Text
								>{moneyFormat({value: menu.menuDiscountedPrice})}
								</Text>
								{
									menu?.menuFlashDiscountedPrice
										? (
											<Text
											>{locale.flashSalePrice.replace('{{price}}', moneyFormat({value: menu.menuFlashDiscountedPrice}))}
											</Text>
										)
										: null
								}
							</>
						) : null
					}
					{
						(menu.kdsName || menu?.menuTags?.length)
							? (
								<div className='flex pt-2 gap-2'>
									{
										menu.kdsName ? (
											<Tag
												className='m-0'
												color='orange'>
												{locale.kdsNameFromTemplate}
											</Tag>

										) : null
									}
									{
										menu?.menuTags?.length ? (
											<div className='flex'>
												{
													menu.menuTags.map((menuTag, index) => (
														<Tag
															key={index}
															color='blue'>
															{menuTagList?.find(p => p?.value === menuTag)?.label || menuTag}
														</Tag>
													))
												}
											</div>
										) : null
									}
								</div>
							)
							: null
					}
					{
						!viewOnly && (
							<div className='flex gap-4 pt-2'>
								<Text
									onClick={() => {
										const actionLocale = menuIsHidden ? locale.showConfirmation : locale.hideConfirmation;
										Modal.confirm({
											title: actionLocale?.title,
											content: actionLocale?.message,
											okText: actionLocale.okText,
											centered: true,
											maskClosable: true,
											okButtonProps: {danger: !menuIsHidden},
											onOk: () => {
												const notifLocale = menuIsHidden ? locale.showMenuSuccess : locale.hideMenuSuccess;
												notification.open({
													type: 'success',
													message: notifLocale.message,
													description: notifLocale.description,
												});
												toggleMenuHidden();
											},
										});
									}}
									className={clsx('cursor-pointer', menuIsHidden ? 'text-antd-blue-6' : 'text-antd-red-6')}>
									{menuIsHidden ? locale.showMenu : locale.hideMenu}
								</Text>
								{
									!duplicateChildMenuExist && isUnmap ? (
										<div>
											<Dropdown
												overlay={(
													<Menu>
														{Object.values(AVAILABLE_POS).map(pos => (
															<Menu.Item
																onClick={() => goToMapMenu(pos.id)}
																disabled={!unmapMenuPosIds.includes(pos.id)}
																key={pos.id}>{`${getPosLabel(pos.label)} Mapping`}</Menu.Item>
														))}
													</Menu>
												)}
											>
												<Text
													className='text-antd-blue-6 cursor-pointer'>
													{locale.mapMenu}
												</Text>
											</Dropdown>
										</div>
									) : null
								}
							</div>
						)
					}
				</div>
				<div className='text-base flex gap-4 p-4 text-black'>
					{
						viewOnly ? null : previewAs ? null : (
							<>
								<DeleteOutlined
									onClick={beforeRemoveMenu}
									className='cursor-pointer text-overlay-40 hover:text-antd-blue-6 duration-300' />
								<TagOutlined
									onClick={() => openMenuTag(menu)}
									className='cursor-pointer text-overlay-40 hover:text-antd-blue-6 duration-300'
								/>
							</>
						)
					}
					{
						promoView
							? null
							: previewAs
								? null
								: (
									<div className='flex gap-4'>
										{viewOnly && (
											<ShoppingCartOutlined
												onClick={openOrderMenuDrawer}
												className='cursor-pointer text-overlay-40 hover:text-antd-blue-6 duration-300'
											/>
										)}
										<OpenDrawerIcon
											onClick={openMenuDrawer}
											className='cursor-pointer text-overlay-40 hover:text-antd-blue-6 duration-300'
										/>
									</div>
								)
					}
					{
						isUnmap ? (
							<ExclamationCircleFilled
								className='text-antd-red-5' />
						) : null
					}
				</div>
			</div>
		</div>
	);
});

MenuItem.defaultProps = {
	menu: {},
	dragHandleProps: {},
	openMenuDrawer: () => null,
	removeMenu: () => null,
	openMenuTag: () => null,
	viewOnly: false,
	promoView: false,
	unmapMenuPosIds: [],
	duplicateChildMenuExist: false,
	locationFilterSetting: {},
	toggleMenuHidden: () => null,
	openOrderMenuDrawer: () => null,
};

MenuItem.propTypes = {
	menu: PropTypes.object,
	dragHandleProps: PropTypes.object,
	openMenuDrawer: PropTypes.func,
	removeMenu: PropTypes.func,
	openMenuTag: PropTypes.func,
	viewOnly: PropTypes.bool,
	promoView: PropTypes.bool,
	unmapMenuPosIds: PropTypes.array,
	duplicateChildMenuExist: PropTypes.bool,
	locationFilterSetting: PropTypes.object,
	toggleMenuHidden: PropTypes.func,
	openOrderMenuDrawer: PropTypes.func,
};

export default MenuItem;