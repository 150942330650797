import React, {useEffect, useRef, useState} from 'react';

import OutletMDRHeader from './OutletMDRHeader';
import OutletTable from '../components/Table';

import {OUTLET_TYPE} from 'utils/constants';
import {getBrandList, getPlatformList} from 'utils/request/global';
import {exportOutletData, getOutletData} from 'utils/request/outlet';
import {exportToTsvFn, generateOutletCSVTemplate, handleErrorFetch, momentDateFormat} from 'utils/utils';

import localization from 'localization';
const locale = localization.Outlet.MDR.Table;

const MDR = () => {
	const refetchTableRef = useRef(null);

	const [loading, setLoading] = useState(false);
	const [brandList, setBrandList] = useState([]);
	const [platformList, setPlatformList] = useState([]);
	const [exportQuery, setExportQuery] = useState(null);

	const convertToPercent = decimal => {
		// Multiply the decimal number by 100 and append the '%' symbol
		const percent = decimal * 100 + '%';
		return percent;
	};

	const tableColumn = [
		{
			title: locale.platform,
			dataIndex: ['Platform', 'label'],
			key: 'platformId',
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				options: platformList.map(paltform => {
					return {
						label: paltform.label,
						value: paltform.id,
					};
				}),
			},
		},
		{
			title: locale.brand,
			dataIndex: ['Brand', 'label'],
			key: 'brandId',
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				options: brandList.map(brand => {
					return {
						label: brand.label,
						value: brand.id,
					};
				}),
			},
		},
		{
			title: locale.mdrAmount,
			dataIndex: 'mdr',
			key: 'mdr',
			search: false,
			render: mdr => convertToPercent(mdr),
		},
		{
			title: locale.settingType,
			dataIndex: 'settingType',
			key: 'settingType',
			search: false,
		},
		{
			title: locale.cofunding,
			dataIndex: 'cofunding',
			key: 'cofunding',
			search: false,
			render: cofunding => convertToPercent(cofunding),
		},
		{
			title: locale.effectiveStartDate,
			dataIndex: 'effectiveDate',
			key: 'effectiveDate',
			valueType: 'dateRange',
			render: (_, {effectiveDate}) => momentDateFormat({dateString: effectiveDate}),
		},
		{
			title: locale.submittedBy,
			dataIndex: ['creator', 'username'],
			key: 'createdBy',
		},
	];

	const handleExport = async ({fileName, type = 'template'}) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportOutletData(exportQuery, OUTLET_TYPE.MDR),
			tsvTemplate: generateOutletCSVTemplate(OUTLET_TYPE.MDR),
		});
	};

	const initialFetch = async () => {
		try {
			setLoading(true);
			const platformResponse = await getPlatformList();
			const brandResponse = await getBrandList();
			if (platformResponse.success) setPlatformList(platformResponse.data.rows);
			if (brandResponse.success) setBrandList(brandResponse.data.rows);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<div
			className="bg-white h-full overflow-auto">
			<OutletMDRHeader
				refetchTableRef={refetchTableRef}
				handleExport={handleExport}
			/>
			<OutletTable
				parentLoading={loading}
				tableColumn={tableColumn}
				fetchFn={getOutletData}
				tableType={OUTLET_TYPE.MDR}
				refetchTableRef={refetchTableRef}
				setExportQuery={setExportQuery}
			/>
		</div>
	);
};

export default MDR;