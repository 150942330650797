import axios from 'utils/axios';
import {OUTLET_TYPE, URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

/**
 * Outlet contain:
 * 1. Location
 * 2. Brand
 * 3. Merchant
 */

export const getOutletData = async (query, type) => {
	try {
		const {limit, page, search} = query;
		if (search?.platformId && ![OUTLET_TYPE.MERCHANT, OUTLET_TYPE.MDR, OUTLET_TYPE.COMPANY].includes(type)) delete search.platformId;
		const params = {
			limit: limit ?? 5,
			offset: Number((page) * limit) || null,
			...search,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/${type}`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const exportOutletData = async ({search}, type) => {
	try {
		if (type === OUTLET_TYPE.MERCHANT) delete search.platformId;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/${type}/export`,
			params: {...search},
		});
		if (data.success) {
			const blobResponse = await (await fetch(data.data.url)).blob();
			return blobResponse;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createNewOutletItem = async (payload, type) => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/${type}`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editOutletItem = async (payload, type) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/cms/${type}`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const activateOutlet = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/outlet/activate`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const bulkToggleAutoInject = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/outlet/bulk-toggle-auto-inject`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getTimezone = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/timezone`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getTrainingList = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/training`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createTrainingSession = async locationId => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/training`,
			data: {locationId},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getTrainingOrderList = async trainingId => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/training-order`,
			params: {trainingId},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const updateTrainingOrder = async ({id, status}) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/training-order`,
			data: {id, status},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const completeTraining = async ({id}) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/training/complete`,
			data: {id},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteTrainingSession = async ({id}) => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/training`,
			data: {id},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};