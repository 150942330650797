import React, {useRef, useState} from 'react';
import ProTable from '@ant-design/pro-table';
import localization from 'localization';
import {handleErrorFetch, sortDirectionConverter} from 'utils/utils';
import {deleteVersion, downloadPos, getUploadedVersion} from 'utils/request/admin';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {Modal, notification} from 'antd';
import {isEmpty} from 'lodash';
import OutletListDrawer from 'components/OutletListDrawer';

const outletListDrawerInitialConfig = {
	visible: false,
	outletList: [],
	versionId: null,
};

const VersionUploaderBody = () => {
	const locale = localization.Admin.VersionUploader.List;
	const tableRef = useRef(null);
	const navigate = useNavigate();

	const [loadingDelete, setLoadingDelete] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);
	const [outletListDrawerConfig, setOutletListDrawerConfig] = useState(outletListDrawerInitialConfig);

	const openOutletList = (outlets, versionId) => {
		setOutletListDrawerConfig({
			visible: true,
			outletList: outlets,
			versionId,
		});
	};

	const closeOutletList = () => setOutletListDrawerConfig(outletListDrawerInitialConfig);

	const handleDownloadPos = async record => {
		try {
			setLoadingDownload(true);
			const response = await downloadPos({posVersionId: record.id});

			if (response.success) {
				const link = document.createElement('a');
				link.href = response.data;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	const handleDelete = async record => {
		try {
			setLoadingDelete(true);
			const response = await deleteVersion({id: record.id});
			if (response.success) {
				tableRef?.current?.reset();
				notification.success({
					message: locale.DeleteSuccess.message,
					description: locale.DeleteSuccess.description,
				});
			}
		} catch (error) {
			notification.error({
				message: locale.DeleteFailed.message,
				description: locale.DeleteFailed.description,
			});
		} finally {
			setLoadingDelete(false);
		}
	};

	const deleteConfirmation = record => {
		Modal.confirm({
			title: locale.DeleteConfirmation.message,
			content: locale.DeleteConfirmation.description.replace('{{ver}}', record.id),
			okText: locale.DeleteConfirmation.okText,
			cancelText: locale.DeleteConfirmation.cancelText,
			centered: true,
			okButtonProps:{loading: loadingDelete},
			onOk: async () => {
				await handleDelete(record);
			},
		});
	};

	const columns = [
		{
			width: 133,
			key: 'id',
			dataIndex: 'id',
			title: locale.versionName,
			render: id => id,
		},
		{
			width: 118,
			key: 'channel',
			dataIndex: 'channel',
			title: locale.channel,
			search: false,
			render: channel => <div className='capitalize'>{channel}</div>,
			filterMultiple: false,
			filters: [
				{
					text: locale.stable,
					value: 'stable',
				},
				{
					text: locale.beta,
					value: 'beta',
				},
			],
		},
		{
			width: 133,
			key: 'locations',
			dataIndex: 'locations',
			title: locale.locations,
			search: false,
			render: (locations, record) => (
				<div>
					{
						locations.length
							? (
								<span
									className='text-antd-blue-6 cursor-pointer'
									onClick={() => {
										openOutletList(locations, record.id);
									}}>{locale.locationsCount.replace('{{count}}', locations.length)}</span>
							)
							: '-'
					}
				</div>
			),
		},
		{
			width: 110,
			key: 'createdBy',
			dataIndex: ['creator', 'username'],
			title: locale.createdBy,
			render: creator => (
				<div>{creator}</div>
			),
		},
		{
			width: 171,
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: locale.createdAt,
			search: false,
			sorter: true,
			defaultSortOrder: 'descend',
			render: createdAt => (
				<div>{moment(createdAt).format('DD MMM YYYY - HH:mm')}</div>
			),
		},
		{
			width: 124,
			key: 'updatedBy',
			dataIndex: ['updater', 'username'],
			title: locale.updatedBy,
			render: updatedBy => (
				<div>{updatedBy ? updatedBy : '-'}</div>
			),
		},
		{
			width: 169,
			key: 'updatedAt',
			dataIndex: 'updatedAt',
			search: false,
			title: locale.updatedAt,
			sorter: true,
			render: updatedAt => (
				<div>{updatedAt ? moment(updatedAt).format('DD MMM YYYY - HH:mm') : '-'}</div>
			),
		},
		{
			key: 'action',
			title: 'Action',
			fixed: 'right',
			align: 'center',
			search: false,
			render: (_, record) => (
				<div className='text-antd-blue-6 flex justify-center gap-4'>
					<div
						className='cursor-pointer'
						onClick={() => navigate(`/admin/version-settings/version-uploader/${record.id}/detail`)}>{locale.detail}</div>
					{
						isEmpty(record.PosVersionSchedules) && (
							<div
								className='cursor-pointer'
								onClick={() => navigate(`/admin/version-settings/version-uploader/${record.id}/edit`)}>{locale.edit}</div>
						)
					}
					<div
						className='cursor-pointer'
						onClick={() => handleDownloadPos(record)}>{locale.download}</div>
					{
						isEmpty(record.PosVersionSchedules) && (
							<div
								className='cursor-pointer text-antd-red-6'
								onClick={() => deleteConfirmation(record)}>{locale.delete}</div>
						)
					}
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				page: params.current - 1,
				sortBy: Object.keys(sorter)?.[0] || 'createdAt',
				sortOrder: sortDirectionConverter(Object.values(sorter)?.[0] || 'desc'),
				search: {
					id: params?.id || null,
					createdBy: params?.createdBy,
					updatedBy: params?.updatedBy,
					channel: filters?.channel?.[0] || null,
				},
			};

			const response = await getUploadedVersion(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<div>
			<OutletListDrawer
				title={locale.LocationDrawer.title.replace('{{id}}', outletListDrawerConfig.versionId)}
				placeholder={locale.LocationDrawer.placeholder}
				visible={outletListDrawerConfig.visible}
				outletList={outletListDrawerConfig.outletList}
				onClose={closeOutletList}
				locationOnly
			/>
			<ProTable
				actionRef={tableRef}
				className="mt-4 ResetSearchTableMargin px-6"
				rowKey='id'
				columns={columns}
				loading={loadingDownload || loadingDelete}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				onReset={() => tableRef?.current?.reset()}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
};

export default VersionUploaderBody;