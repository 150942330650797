import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {USER_ROLES} from 'utils/constants';
import {handleErrorFetch} from 'utils/utils';
import {createNewUser, updateUserDetails} from 'utils/request/user';
import rolePresetConfig from './rolePresetConfig';

import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

import {Button, Card, Form, Input, notification, Radio, Select} from 'antd';
const {Option, OptGroup} = Select;

import localization from 'localization';
import UserPasswordModal from '../../UserPasswordModal';
const locale = localization.Admin.UserForm.Body;

const UserFormBody = ({isEdit, userDetail}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [loading, setLoading] = useState(false);
	const [usernameAddon, setUsernameAddon] = useState('');

	const [userPasswordModalVisible, setUserPasswordModalVisible] = useState(false);
	const [createdUserDetail, setCreatedUserDetail] = useState({});

	const generateOption = () => {
		const singleOption = [];
		const multipleOption = [];

		for (const role in USER_ROLES) {
			const objValue = USER_ROLES[role];
			if (isObject(objValue)) {

				multipleOption.push({
					title: role,
					options: Object.values(objValue),
				});

			} else {
				singleOption.push({
					value: objValue,
				});
			}
		}

		return (
			<>
				<OptGroup label="Admin">
					{
						singleOption.map(option => (
							<Option
								key={option.value}
								value={option.value}>{option.value}</Option>
						))
					}
				</OptGroup>
				{
					multipleOption.map(groupOption => (
						<OptGroup
							key={groupOption.title}
							label={groupOption.title}>
							{
								groupOption.options.map(option => (
									<Option
										key={option}
										value={option}>{option}</Option>
								))
							}
						</OptGroup>
					))
				}
			</>
		);
	};

	const rolePresetChange = val => {
		const selectedRolePreset = rolePresetConfig.find(rolePreset => rolePreset.value === val);
		if (isEmpty(selectedRolePreset)) return;
		if (!isEdit) setUsernameAddon(selectedRolePreset.addonBefore);
		form.setFieldsValue({
			roles: selectedRolePreset.scope,
		});
	};

	const handleSubmit = async e => {
		try {
			setLoading(true);
			const {username, roles} = e;
			let payload = {};

			if (isEdit) {
				payload = {
					id: userDetail.id,
					roles,
				};
			} else {
				payload = {
					username: `${usernameAddon}${username}`,
					roles,
				};
			}

			const fetchFn = isEdit ? updateUserDetails : createNewUser;
			const response = await fetchFn(payload);
			if (response.success) {
				if (isEdit) {
					handleSubmitDone();
				} else {
					setCreatedUserDetail(response.data.user);
					setUserPasswordModalVisible(true);
				}
			} else throw {};

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmitDone = () => {
		const actionLocale = isEdit ? locale.EditSuccess : locale.CreateSuccess;
		notification.success({
			message: actionLocale.message,
			description: actionLocale.description,
		});
		navigate('/admin/user-list');
	};

	useEffect(() => {
		if (isEdit) {
			const validAddon = rolePresetConfig.map(rolePreset => {
				return rolePreset.addonBefore;
			});

			const userDetailAddon = userDetail?.username?.split('_')[0];

			const payload = {
				roles: userDetail.roles,
			};

			if (validAddon.some(addOn => addOn === `${userDetailAddon}_`)) {
				setUsernameAddon(`${userDetailAddon}_`);
				payload.username = userDetail?.username?.split('_')[1];
			} else {
				payload.username = userDetail.username;
			}

			form.setFieldsValue(payload);
		}
	}, []);

	return (
		<>
			<UserPasswordModal
				visible={userPasswordModalVisible}
				userDetail={createdUserDetail}
				onClose={handleSubmitDone}
			/>
			<div className='px-6'>
				<Card
					title={locale.title}
				>
					<Form
						form={form}
						layout="vertical"
						name="adminUserForm"
						scrollToFirstError
						onFinish={e => !loading && handleSubmit(e)}
						onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
						requiredMark={false}
					>
						<div className='max-w-md'>
							<Form.Item
								label={locale.rolePresetLabel}
								name="rolePreset"
								rules={[{required: false}]}
							>
								<Radio.Group
									onChange={e => rolePresetChange(e.target.value)}>
									{rolePresetConfig.map(rolePreset => (
										<Radio
											value={rolePreset.value}
											key={rolePreset.value}>{rolePreset.label}</Radio>
									))}
								</Radio.Group>
							</Form.Item>
							<Form.Item
								label={locale.usernameLabel}
								name="username"
								rules={[{required: true, message: locale.usernameLabelRequired}]}
							>
								<Input
									disabled={isEdit}
									onChange={e => form.setFieldsValue({username: e.target.value.trim().toLocaleLowerCase()})}
									addonBefore={usernameAddon}
									placeholder={locale.usernameLabelRequired}
								/>
							</Form.Item>
							{/* <Form.Item
							label={locale.emailLabel}
							name="email"
							rules={[{required: true, type: 'email', message: locale.emailLabelRequired}]}
						>
							<Input
								disabled={isEdit}
								placeholder={locale.emailLabelRequired}
							/>
						</Form.Item> */}
							<Form.Item
								label={locale.userScopeLabel}
								name="roles"
								rules={[{required: false}]}
							>
								<Select
									mode="multiple"
									className='w-full'
									placeholder={locale.userScopePlaceholder}
								>
									{
										generateOption()
									}
								</Select>
							</Form.Item>
						</div>
						<div className='flex gap-2 justify-end'>
							<Form.Item>
								<Button
									onClick={() => navigate('/admin/user-list')}
									loading={loading}>
									{locale.cancel}
								</Button>
							</Form.Item>
							<Form.Item>
								<Button
									loading={loading}
									type="primary"
									htmlType="submit">
									{isEdit ? locale.save : locale.create}
								</Button>
							</Form.Item>
						</div>
					</Form>
				</Card>
			</div>
		</>
	);
};

UserFormBody.defaultProps = {
	isEdit: false,
	userDetail: {},
};

UserFormBody.propTypes = {
	isEdit: PropTypes.bool,
	userDetail: PropTypes.object,
};

export default UserFormBody;