import React, {useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import MenuTemplateFormBody from './MenuTemplateFormBody';
import MenuTemplateFormHeader from './MenuTemplateFormHeader';

import {Modal, notification} from 'antd';

import {saveMenuTemplateDraft} from 'utils/request/salesChannel';

import localization from 'localization';
import {menuTemplateBodyFormatter} from 'utils/utils';
const locale = localization.MenuTemplate.MenuTemplateForm;

const MenuTemplateForm = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [saveLoading, setSaveLoading] = useState(false);
	const formBodyRef = useRef(null);

	const menuTemplateLabel = location.state?.menuTemplateLabel;
	let menuTemplateObj = location.state?.menuTemplateObj;
	const draftId = location.state?.draftId;

	const saveToDraft = async () => {
		try {
			setSaveLoading(true);
			if (formBodyRef.current) {
				const response = await saveMenuTemplateDraft({
					templateId: draftId,
					obj: formBodyRef.current.getMenuTemplateState().obj,
				});
				if (response.success) {
					notification.open({
						message: locale.SaveSuccessAlert.message,
						description: locale.SaveSuccessAlert.description,
						type: 'success',
					});
				} else {
					notification.open({
						message: 'Error',
						description: response.error,
						type: 'warning',
					});
				}
			}
		} catch (error) {
			notification.open({
				message: 'Error',
				description: error?.response?.data?.message || error?.message ||'Please Contact Tech Team',
				type: 'warning',
			});
		} finally {
			setSaveLoading(false);
		}
	};

	const closeConfirmation = () => {
		Modal.confirm({
			title: locale.CloseConfirmation.title,
			content: locale.CloseConfirmation.content,
			centered: true,
			maskClosable: true,
			onOk: () => navigate('/sales-channel/menu-template', {state: {activeTabs: 'draft'}}),
			okText: locale.CloseConfirmation.okText,
			cancelText: locale.CloseConfirmation.cancelText,
		});
	};

	menuTemplateObj = menuTemplateBodyFormatter(menuTemplateObj);

	return (
		<div className='bg-white flex-1 overflow-auto flex flex-col'>
			<MenuTemplateFormHeader
				closeConfirmation={closeConfirmation}
				saveLoading={saveLoading}
				saveToDraft={saveToDraft}
				menuTemplateLabel={menuTemplateLabel}
			/>
			<MenuTemplateFormBody
				closeConfirmation={closeConfirmation}
				ref={formBodyRef}
				menuTemplateLabel={menuTemplateLabel}
				menuTemplateObj={menuTemplateObj}
				draftId={draftId}
			/>
		</div>
	);
};

export default MenuTemplateForm;