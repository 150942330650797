import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import {Typography} from 'antd';
const {Text} = Typography;

const homePath = {
	url: '/',
	label: 'Home',
};

const Breadcrumb = ({customLabel, newType}) => {
	const navigate = useNavigate();
	const {hash} = useParams();
	const [locationPaths, setLocationPaths] = useState([homePath]);

	const getBreadcrumb = () => {
		const locationPathsArr = location.pathname.split('/') || [];
		let locationPathsTemp = [];

		let nextUrl = '';
		let labelTemp = '';

		if (hash) locationPathsArr.splice(3, 1);

		locationPathsArr.every((path, index) => {
			if (!path) {
				locationPathsTemp.push(homePath);
				return true;
			} else {
				nextUrl += `/${path}`;
				labelTemp += ` ${path.split('-').join(' ')}`;
				if (['detail', 'edit'].some(k => path.includes(k))) {
					return false;
					// const pathTemp = {
					// 	// url: `/sales-channel/menu-template/detail/${locationPathsArr[index + 1]}`,
					// 	// label: `Menu Template ${locationPathsArr[index + 1]}`,
					// 	url: nextUrl,
					// 	label: labelTemp,
					// };
					// locationPathsTemp.push(pathTemp);
					// return false;
				}
				if (path === 'new') {
					const pathTemp = {
						url: nextUrl,
						label: customLabel || `Create New ${newType}`,
					};
					locationPathsTemp.push(pathTemp);
					return false;
				}
				if (
					['masterlist', 'sales-channel', 'promo', 'outlet', 'watchtower',
						'admin', 'sales-dashboard', 'pos-settings', 'version-settings'].includes(path) && index === 1 || index === 2
				) {
					labelTemp += ' - ';
					return true;
				}
				if (nextUrl.includes('review')) {
					labelTemp = 'Review Template';
				}
				const pathTemp = {
					url: nextUrl,
					label: index === locationPathsArr.length - 1 ? customLabel || labelTemp : labelTemp,
				};
				locationPathsTemp.push(pathTemp);
				return true;
			}
		});

		setLocationPaths(locationPathsTemp);
	};

	useEffect(() => {
		getBreadcrumb();
	}, [customLabel, location.pathname]);

	return (
		<div className="flex items-center gap-x-2 capitalize">
			{locationPaths.map((path,index) => {
				const isLastPath = index === locationPaths.length - 1;
				return (
					<div
						className='flex gap-x-2'
						key={index}>
						<Text
							type={!isLastPath && 'secondary'}
							className={clsx(
								!customLabel && 'capitalize',
								!isLastPath && 'cursor-pointer',
							)}
							onClick={() => {
								if (!isLastPath)
									navigate(path.url);
							}} >{path.label}</Text>
						{!isLastPath && (
							<Text type='secondary'>/</Text>
						)}
					</div>
				);
			})}
		</div>
	);
};

Breadcrumb.propTypes = {
	customLabel: PropTypes.string,
	newType: PropTypes.string,
};

export default Breadcrumb;
