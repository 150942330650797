import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Components
import MenuTemplateFormBody from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody';

// Utils
import {menuTemplateBodyFormatter} from 'utils/utils';
import {getMenuTemplateDetail} from 'utils/request/salesChannel';

import {Spin} from 'antd';

const MenuTemplateWrapper = ({menuTemplateId, cart, setCart}) => {
	const [loading, setLoading] = useState(true);
	const [menuTemplateObj, setMenuTemplateObj] = useState({});

	const initialFetch = async () => {
		try {
			setLoading(true);
			const response = await getMenuTemplateDetail({id: menuTemplateId});
			if (response?.success) {

				let tempMenuTemplateObj = response.data.details.body;
				tempMenuTemplateObj = menuTemplateBodyFormatter(tempMenuTemplateObj);
				setMenuTemplateObj(tempMenuTemplateObj);
			} else {
				throw {};
			}
		} catch (error) {
			// notification.open({
			// 	message: 'Error',
			// 	description: 'Failed to load menu template data',
			// 	type: 'warning',
			// });
			// navigate('/sales-channel/sync-request');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (menuTemplateId) initialFetch();
	}, []);

	return loading
		? (
			<div className='flex items-center justify-center mt-4'>
				<Spin size='lg' />
			</div>
		)
		: (
			<MenuTemplateFormBody
				menuTemplateLabel={'menuTemplateLabel'}
				menuTemplateObj={menuTemplateObj}
				providedLocation={[]}
				cart={cart}
				setCart={setCart}
				// previewAsDep={setPreviewAsWatcher}
				viewOnly
			/>
		);
};

MenuTemplateWrapper.defaultProps = {
	menuTemplateId: null,
	cart: [],
	setCart: () => null,
};

MenuTemplateWrapper.propTypes = {
	menuTemplateId: PropTypes.number,
	cart: PropTypes.array,
	setCart: PropTypes.func,
};

export default MenuTemplateWrapper;