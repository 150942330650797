import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';

// import usePrevious from 'hooks/usePrevious';

import enUS from 'antd/lib/locale/en_US';
import {Button, ConfigProvider, notification, Popconfirm} from 'antd';
import {InfoCircleFilled} from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';
import cloneDeep from 'lodash/cloneDeep';

import {handleErrorFetch} from 'utils/utils';
import {activateOutlet, bulkToggleAutoInject} from 'utils/request/outlet';

import './table.less';

const defaultSearch = {
	menuLabel: null,
	menuCode: null,
	menuId: null,
	tags: null,
	createdBy: null,
	updatedBy: null,
	isActive: null,
};

const defaultQueryParams = {
	search: defaultSearch,
	page: 0,
	limit: 50,
};

let highlightNewDataTimeout = null;

import localization from 'localization';
import {OUTLET_TYPE} from 'utils/constants';
import {forwardRef} from 'react';
const locale = localization.Outlet.Activation.Table;

const OutletTable = forwardRef(({
	setExportQuery, scrollToTop, refetchTableRef, fetchFn, tableColumn, tableType,
	parentLoading,
}, ref) => {
	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);

	const [selected, setSelected] = useState([]);
	const [turnOffable, setTurnOffable] = useState([]);
	const [turnOnable, setTurnOnable] = useState([]);
	const [activeable, setActiveable] = useState([]);

	const [highlightNewData, setHighlightNewData] = useState(false);

	const tableRef = useRef(null);
	const resetRefFlag = useRef(false);

	const {search, page, limit} = queryParams;

	const fetchTableData = async (config = {
		reset: false,
		highlightNewData: false,
		captureLatestUpdateTime: false,
	}) => {
		try {
			const {reset, highlightNewData} = config;
			setLoading(true);
			let tableDataResponse;
			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				tableDataResponse = await fetchFn({...defaultQueryParams, limit}, tableType);
			} else {
				tableDataResponse = await fetchFn(queryParams, tableType);
			}
			if (tableDataResponse?.success) {
				setTableData(tableDataResponse.data);
				highlightNewData && setHighlightNewData(true);
				scrollToTop();
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			resetRefFlag.current = false;
			setLoading(false);
		}
	};

	const toggleAutoInject = async record => {
		try {
			setLoading(true);
			const response = await bulkToggleAutoInject({merchants: [
				{
					merchantId: record.id,
					autoInjectFlag: !record.autoInjectFlag,
				},
			]});
			if (response.success) {
				const setter = record.autoInjectFlag ? setTurnOffable : setTurnOnable;
				setter(state => state.filter(merchantId => merchantId !== record.id));
				setSelected(state => state.filter(merchantId => merchantId !== record.id));

				const stateString = (record.autoInjectFlag ? locale.off : locale.on).toLowerCase();
				notification.open({
					message: locale.AutoInjectAction.Notification.Success.message.replace('{{state}}', stateString),
					description: locale.AutoInjectAction.Notification.Success.description.replace('{{state}}', stateString),
					type: 'success',
				});
				fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useImperativeHandle(ref, () => ({
		handleToggleAutoInject(prop) {
			toggleAutoInject(prop);
		},
	}));

	const handleBatchToggle = async (getter, setter, autoInjectFlag) => {
		try {
			setLoading(true);
			const payload = getter.map(merchantId => {
				return {
					merchantId: merchantId,
					autoInjectFlag,
				};
			});

			const response = await bulkToggleAutoInject({merchants: payload});
			if (response.success) {
				setter([]);
				setSelected(state => state.filter(merchantId => !getter.includes(merchantId)));
				fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleBatchActivation = async () => {
		const payloads = activeable.map(merchantId => {
			return {
				merchantId,
			};
		});

		const fetchFn = async () => {
			setLoading(true);
			payloads.forEach(async payload => {
				try {
					await activateOutlet(payload);
				} catch (error) {
					handleErrorFetch(error);
				}
			});
			setLoading(false);

			await new Promise(resolve => {
				setTimeout(resolve, 100);
			});
			fetchTableData();
		};

		await fetchFn();
		setSelected([]);
		setTurnOffable([]);
		setTurnOnable([]);
		setActiveable([]);
	};

	const onSelectAllData = async () => {
		try {
			setLoading(true);
			const response = await fetchFn({...queryParams, limit: tableData.count, page: 0}, tableType);
			if (response.success) {

				const tempSelected = [];
				const tempTurnoffable = [];
				const tempTurnonable = [];
				const tempActiveable = [];

				response.data.rows.forEach(item => {
					if (item.autoInjectFlag) tempTurnoffable.push(item.id);
					else tempTurnonable.push(item.id);
					tempSelected.push(item.id);
					if (item.activationStatus !== 'Success') tempActiveable.push(item.id);
				});

				setSelected(tempSelected);
				setTurnOffable(tempTurnoffable);
				setTurnOnable(tempTurnonable);
				setActiveable(tempActiveable);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelected = [];
			const tempTurnoffable = [];
			const tempTurnonable = [];
			const tempActiveable = [];

			changeRows.forEach(item => {
				if (item.autoInjectFlag) tempTurnoffable.push(item.id);
				else tempTurnonable.push(item.id);
				tempSelected.push(item.id);
				if (item.activationStatus !== 'Success') tempActiveable.push(item.id);
			});

			setTurnOffable(state => state.concat(tempTurnoffable));
			setTurnOnable(state => state.concat(tempTurnonable));
			setSelected(state => state.concat(tempSelected));
			setActiveable(state => state.concat(tempActiveable));
		} else {
			const idOfChangeRows = changeRows.map(item => item.id);
			setSelected(state => state.filter(item => !idOfChangeRows.includes(item)));
			setTurnOffable(state => state.filter(item => !idOfChangeRows.includes(item)));
			setTurnOnable(state => state.filter(item => !idOfChangeRows.includes(item)));
			setActiveable(state => state.filter(item => !idOfChangeRows.includes(item)));
		}
	};

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			setSelected(state => state.concat(record.id));
			setActiveable(state => state.concat(record.id));
			if (record.autoInjectFlag) setTurnOffable(state => state.concat(record.id));
			else setTurnOnable(state => state.concat(record.id));
		} else { // Uncheck
			setSelected(state => state.filter(item => item !== record.id));
			setTurnOffable(state => state.filter(item => item !== record.id));
			setTurnOnable(state => state.filter(item => item !== record.id));
			setActiveable(state => state.filter(item => item !== record.id));
		}
	};

	const rowSelection = {
		selectedRowKeys: selected,
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			disabled: null,
			name: record.name,
		}),
	};

	const renderTableAlert = ({selectedRowKeys}) =>
		selectedRowKeys.length > 0 && (
			<div className='flex gap-3 items-center'>
				<InfoCircleFilled className='text-antd-blue-6' />
				<div>
					<span className='font-semibold'>{selectedRowKeys.length}</span> {locale.TableAlert.itemsSelected}
				</div>
				<div>·</div>
				<a
					className='text-antd-blue-6'
					onClick={onSelectAllData}
				>
					{locale.TableAlert.selectAll.replace('{{qty}}', selected.length || 0)}
				</a>
			</div>
		);

	const renderTableAlertOption = () => (
		<div className='flex gap-2 items-center'>
			<div
				onClick={() => {
					setSelected([]);
					setTurnOffable([]);
					setTurnOnable([]);
					setActiveable([]);
				}}
				className='text-antd-blue-6 px-2 cursor-pointer'>Clear</div>

			<Popconfirm
				title={locale.TableAlert.bulkTurnOffConfirmation.replace('{{qty}}', turnOffable.length || 0)}
				onConfirm={() => handleBatchToggle(turnOffable, setTurnOffable, false)}
				okText={locale.TableAlert.okText}
				cancelText={locale.TableAlert.cancelText}
				disabled={loading || turnOffable.length === 0}
			>
				<Button
					size='small'
					type="primary"
					danger
					disabled={loading || turnOffable.length === 0}
				>
					{locale.TableAlert.turnOffSelected.replace('{{qty}}', turnOffable.length || 0)}
				</Button>
			</Popconfirm>

			<Popconfirm
				title={locale.TableAlert.bulkTurnOnConfirmation.replace('{{qty}}', turnOnable.length || 0)}
				onConfirm={() => handleBatchToggle(turnOnable, setTurnOnable, true)}
				okText={locale.TableAlert.okText}
				cancelText={locale.TableAlert.cancelText}
				disabled={loading || turnOnable.length === 0}
			>
				<Button
					size='small'
					type="primary"
					disabled={loading || turnOnable.length === 0}
				>
					{locale.TableAlert.turnOnSelected.replace('{{qty}}', turnOnable.length || 0)}
				</Button>
			</Popconfirm>

			<Popconfirm
				title={locale.TableAlert.activateConfirmation.replace('{{qty}}', activeable.length || 0)}
				onConfirm={() => handleBatchActivation()}
				okText={locale.TableAlert.okText}
				cancelText={locale.TableAlert.cancelText}
				disabled={loading || activeable.length === 0}
			>
				<Button
					size='small'
					disabled={loading || activeable.length === 0}
				>
					{locale.TableAlert.activateSelected.replace('{{qty}}', activeable.length || 0)}
				</Button>
			</Popconfirm>

		</div>
	);

	useEffect(() => {
		if (resetRefFlag.current) return;
		refetchTableRef.current = fetchTableData;
		(async () => await fetchTableData())();
	}, [search, page, limit]);

	useEffect(() => {
		// To record export query
		setExportQuery({search: queryParams.search});
		refetchTableRef.current = fetchTableData;
	}, [search]);

	useEffect(() => {
		// To highlight new added data
		if (highlightNewData) {
			highlightNewDataTimeout = setTimeout(() => {
				setHighlightNewData(false);
			}, 2000);
		}
		return () => clearTimeout(highlightNewDataTimeout);
	}, [highlightNewData]);

	return (
		<div className="pb-8 OutletTable">
			<ConfigProvider locale={enUS}>
				<ProTable
					rowKey='id'
					actionRef={tableRef}
					id='outlet-table'
					loading={loading || parentLoading}
					pagination={{
						defaultPageSize: limit,
						current: page + 1,
						total: tableData?.count,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showQuickJumper: true,
					}}
					onChange={handleChange}
					onSubmit={params => {
						Object.keys(params).forEach(k => !params[k] && delete params[k]);

						const booleanHandler = paramsKey => {
							switch (params[paramsKey]) {
							case 'active':
								params[paramsKey] = true;
								break;
							case 'inactive':
								params[paramsKey] = false;
								break;
							case 'all':
							default:
								params[paramsKey] = null;
								break;
							}
						};

						['isActive', 'printTax', 'isNew', 'isSubBrand'].forEach(paramsKey => {
							if (params[paramsKey]) booleanHandler(paramsKey);
						});


						if (params.effectiveDate?.length) {
							params.effectiveDateFrom = params.effectiveDate[0];
							params.effectiveDateTo = params.effectiveDate[1];
							delete params.effectiveDate;
						}

						if (Array.isArray(params.tags)) {
							params.tags = params.tags.join(',').toLowerCase();
						}
						const queryParamsTemp = cloneDeep(defaultQueryParams);
						queryParamsTemp.search = {...defaultSearch, ...params};
						queryParamsTemp.limit = queryParams.limit;
						setQueryParams(queryParamsTemp);
					}}
					onReset={() => fetchTableData({reset: true})}
					options={false}
					search={{
						layout: 'vertical',
					}}
					scroll={{x: 'max-content'}}
					dataSource={tableData?.rows}
					tableAlertRender={(renderTableAlert)}
					tableAlertOptionRender={(renderTableAlertOption)}
					rowSelection={tableType === OUTLET_TYPE.MERCHANT && rowSelection}
					columns={tableColumn}
				/>
			</ConfigProvider>
		</div>
	);
});

OutletTable.defaultProps = {
	setExportQuery: () => null,
	scrollToTop: () => null,
	refetchTableRef: {},
	tableType: null,
	setLatestUpdateInfo: () => null,
	fetchFn: () => null,
	tableColumn: [],
	parentLoading: false,
};

OutletTable.propTypes = {
	setExportQuery: PropTypes.func,
	scrollToTop: PropTypes.func,
	refetchTableRef: PropTypes.object,
	tableType: PropTypes.string,
	setLatestUpdateInfo: PropTypes.func,
	fetchFn: PropTypes.func,
	tableColumn: PropTypes.array,
	parentLoading: PropTypes.bool,
};

export default OutletTable;