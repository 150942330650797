import React from 'react';
import PropTypes from 'prop-types';

import {Descriptions, Modal} from 'antd';

import {get} from 'lodash';
import moment from 'moment';

import localization from 'localization';
const locale = localization.Admin.VersionTracker.List;

const VersionTrackerModal = ({visible, data, close}) => {

	const getDescriptionItem = () => {
		return [
			{
				label: locale.locationGroup,
				key: 'locationGroup',
				span: 4,
				value: get(data, ['locationGroup', 'name'], '-'),
			},
			{
				label: locale.currentVer,
				key: 'currentVer',
				span: 2,
				value: get(data, ['latestVersionUpdate', 'posVersionId'], '-'),
			},
			{
				label: locale.channel,
				key: 'currentChannel',
				span: 2,
				value: get(data, ['latestVersionUpdate', 'PosVersion', 'channel'], '-'),
			},
			{
				label: locale.lastUpdate,
				key: 'lastUpdate',
				span: 4,
				value: get(data, ['latestVersionUpdate', 'updatedAt'], null)
					? moment(get(data, ['latestVersionUpdate', 'updatedAt'])).format('DD MMM YYYY - HH:mm')
					: '-',
			},
			{
				label: locale.newReleaseDate,
				key: 'newReleaseDate',
				span: 2,
				value: get(data, ['latestVersionSchedule', 'releaseDate'], null)
					? moment(get(data, ['latestVersionSchedule', 'releaseDate'])).format('DD MMM YYYY - HH:mm')
					: '-',
			},
			{
				label: locale.rollOutBy,
				key: 'rollOutBy',
				span: 2,
				value: get(data, ['latestVersionUpdate', 'PosVersionSchedule', 'creator', 'username'], '-'),
			},
			{
				label: locale.newestVer,
				key: 'newestVer',
				span: 2,
				value: get(data, ['latestVersionSchedule', 'posVersionId'], '-'),
			},
			{
				label: locale.channel,
				key: 'newestChannel',
				span: 2,
				value: get(data, ['latestVersionSchedule', 'PosVersion', 'channel'], '-'),
			},
			{
				label: locale.previousVer,
				key: 'previousVer',
				span: 2,
				value: get(data, ['latestVersionUpdate', 'posVersionId'], '-'),
			},
			{
				label: locale.channel,
				key: 'previousChannel',
				span: 2,
				value: get(data, ['latestVersionUpdate', 'PosVersion', 'channel'], '-'),
			},
		];
	};

	return (
		<Modal
			width={868}
			centered
			title={locale.modalTitle.replace('{{location}}', data?.label)}
			visible={visible}
			onCancel={close}
			cancelButtonProps={{style: {display: 'none'}}}
		>
			<Descriptions
				bordered
			>
				{
					getDescriptionItem().map(descItem => (
						<Descriptions.Item
							labelStyle={{
								width: '80px',
							}}
							key={descItem.key}
							label={descItem.label}
							span={descItem.span}
						>
							{descItem.value}
						</Descriptions.Item>
					))
				}
			</Descriptions>
		</Modal>
	);
};

VersionTrackerModal.defaultProps = {
	visible: false,
	close: () => null,
	data: {},
};

VersionTrackerModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
};

export default VersionTrackerModal;